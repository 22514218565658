const state = {
  authenticated: false,
  loggingOut: false,
  profile: {},
  user: {
    email: null,
    name: null,
    pipedrive_token: null,
    partner: false,
  },
  userId: null,
};

const mutations = {
  setAuthenticated(state, authState) {
    state.authenticated = authState;
  },
  setUserId(state, id) {
    state.userId = id;
  },
  setLoggingOut(state, value) {
    state.loggingOut = value;
  },
  setProfile(state, newProfile) {
    state.profile = newProfile;
  },
  updateUser(state, newUser) {
    Object.assign(state.user, newUser);
  },
};

const actions = {
  updateAuthInfo({ commit }, payload) {
    commit("setAuthenticated", payload.loggedIn);
    commit("setProfile", payload.profile);

    // this is our user id for our primary DB. however we receive it from auth0
    // in a nested data structure of JWT claims, so dig it out for easier access.
    const userId =
      payload.profile &&
      payload.profile["https://hasura.io/jwt/claims"] &&
      payload.profile["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
    commit("setUserId", userId || null); // unset if logged out
  },
  updateUserProperties({ commit }, payload) {
    commit("updateUser", payload);
  },
  setForcedUser({ commit }, id) {
    commit("setAuthenticated", true);
    commit("setUserId", id);
  },
  startLogout({ commit }) {
    commit("setLoggingOut", true);
    // clear sensitive user properties
    commit("updateUser", { pipedrive_token: null });
  },
};

const getters = {
  hadAuthenticatedSession(state) {
    return state.authenticated || state.loggingOut;
  },
  userEmail(state) {
    return state.user && state.user.email;
  },
  userId(state) {
    return state.userId;
  },
  userName(state) {
    return state.user && state.user.name;
  },
  userIsPartner(state) {
    return state.user && state.user.partner;
  },
  pipedriveReady(state) {
    return state.user && !!state.user.pipedrive_token;
  },
  pipedriveToken(state) {
    return state.user && state.user.pipedrive_token;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
