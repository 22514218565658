// shared state for calendar view / components

const state = {
  activities: [],
};

const mutations = {
  addActivity(state, activity) {
    state.activities.push(activity);
  },
  setActivities(state, value) {
    if (!Array.isArray(value)) throw "Non-array value submitted for activities";
    state.activities = value;
  },
  updateActivity(state, activity) {
    const id = state.activities.findIndex((a) => a.id == activity.id);
    if (id != -1) {
      state.activities.splice(id, 1, activity);
    }
  },
};

const actions = {
  addCalendarActivity({ commit }, activity) {
    commit("addActivity", activity);
  },
  setCalendarActivities({ commit }, activities) {
    commit("setActivities", activities);
  },
  updateCalendarActivity({ commit }, activity) {
    commit("updateActivity", activity);
  },
};

const getters = {
  calendarActivities(state) {
    return state.activities;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
