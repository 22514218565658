import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "./registerServiceWorker";

import { apolloProvider } from "@/init/apollo";
import { initSentry } from "@/init/sentry";
import FontAwesomeIcon from "@/common/fontAwesome";
import { clickOutside } from "@/common/directives";
import filters from "@/common/filters";
import { initToasted } from "@/common/toasted";
import AuthService from "@/services/authService";
import VueChartkick from "vue-chartkick";
import "chartkick/chart.js";
import mousetrap from "mousetrap";

const app = createApp(App);

app.use(apolloProvider);
app.use(router);
app.use(store);

initSentry(app, router);
const toasted = initToasted({ duration: 4000 });
app.use(VueChartkick);

// enabling of devtools via config.devtools has been removed, we can now use the
// __VUE_PROD_DEVTOOLS__ compile-time flag - details:
// https://github.com/vuejs/core/tree/main/packages/vue#bundler-build-feature-flags

// make auth service globally available - maybe replace this with provide/inject later
app.config.globalProperties.$auth = AuthService;

// filters are not supported in vue3, but we can use this global to ease transition
app.config.globalProperties.$filters = filters;

// make mousetrap available via $mousetrap
app.config.globalProperties.$mousetrap = mousetrap();

// set up toasts
app.config.globalProperties.$toasted = toasted;

// global components
app.component("FontAwesomeIcon", FontAwesomeIcon);

// global custom directives
app.directive("click-outside", clickOutside);

app.mount("#app");
