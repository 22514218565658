import { createStore } from "vuex";
import calendar from "@/store/calendar.module";
import google from "@/store/google.module";
import member from "@/store/member.module";
import user from "@/store/user.module";

export const store = createStore({
  modules: {
    calendar,
    google,
    member,
    user,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});

export default store;
