<template>
  <section class="notes section">
    <div class="header">
      <h2 class="is-size-3 is-inline-block">Notes</h2>
      <a v-show="!addingNote" class="button is-pulled-right" @click="toggleAddNote">Add Note</a>
    </div>
    <div v-show="addingNote" class="new-note">
      <div class="media">
        <figure class="media-left">
          <img :src="avatarUrl" class="avatar" :alt="userName" :title="userName" />
        </figure>
        <div class="media-content">
          <NoteEditor v-model="noteContent" :save-interrupted="noteCacheKey" />
          <div class="buttons">
            <button class="button" @click="saveNote">Add Note</button>
            <button class="button is-text" @click="cancelNote">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <div id="pipedrive-notes">
      <CompanyNote v-for="note in notes" :key="note.id" :note="note" @update="updateNote" />
    </div>
  </section>
</template>

<script>
import CompanyNote from "@/components/CompanyNote.vue";
import NoteEditor from "@/components/NoteEditor.vue";
import { retrieveNoteCache } from "@/common/notes";
import { activities, notes } from "@/common/pipedrive/resources";
import { sanitizeHTML } from "@/common/pipedrive/util";
import { uniqueByProperty } from "@/utils/array";
import { toDate } from "@/utils/date";

export default {
  components: { CompanyNote, NoteEditor },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      addingNote: false,
      noteContent: "",
      pipedriveNotes: [],
      pipedriveActivities: [],
    };
  },
  computed: {
    avatarUrl() {
      return this._avatarUrl(this.myUser);
    },
    myUser() {
      return this.users.find((user) => user.email == this.$store.getters.userEmail);
      // return this.users.find(user => user.email == "joe@heavybit.com");
    },
    notes() {
      const notes = [];
      // preformat users for faster lookup
      const users = new Map(this.users.map((user) => [user.id, user]));

      // Create note objects from pipedrive activities
      this.pipedriveActivities.forEach((a) => {
        if (a.note == null || a.note.length == 0) return;
        let user = users.get(a.assigned_to_user_id);
        notes.push({
          id: a.id,
          type: a.type,
          content: a.note,
          subject: a.subject,
          add_time: `${a.due_date} ${a.due_time || "00:00"}:00`, // Activity due_time is the equivalent to the note add_time
          user: {
            id: a.assigned_to_user_id,
            icon_url: this._avatarUrl(user),
            name: user?.name || "Unknown",
          },
        });
      });

      // Combine the pipedrive notes with the pipedrive activities
      this.pipedriveNotes.forEach((n) => {
        notes.push({
          id: n.id,
          type: "additional_notes",
          content: n.content,
          subject: "Additional Notes",
          add_time: n.add_time,
          user: {
            id: n.user_id,
            icon_url: this._avatarUrl(n.user),
            name: n.user.name || "Unknown",
          },
        });
      });

      // Add the notes in reverse order so they are in chronological order
      return notes.sort((a, b) => toDate(b.add_time) - toDate(a.add_time));
    },
    noteCacheKey() {
      return "newAdditional." + this.id;
    },
    userName() {
      return this.$store.getters.userName;
    },
    users() {
      const noteUsers = this.pipedriveNotes.map((note) => {
        // reconstruct a user object since user_id is a separate property
        return Object.assign({ id: note.user_id }, note.user);
      });
      return uniqueByProperty(noteUsers, "email");
    },
  },
  mounted() {
    this._fetchNotes();
    this._fetchActivities();
    this._initNoteContent();
  },
  methods: {
    cancelNote() {
      this._resetNote();
    },
    sanitize(html) {
      return sanitizeHTML(html, true, 40);
    },
    saveNote() {
      notes
        .create({ org_id: this.id, content: this.sanitize(this.noteContent) })
        .then(() => {
          this._fetchNotes(); // refresh
          this._resetNote();
          this.$toasted.success("New note added");
        })
        .catch(() => this.$toasted.error("Unable to add new note"));
    },
    toggleAddNote() {
      this.addingNote = !this.addingNote;
    },
    updateNote(kind, updated) {
      if (kind == "note") {
        const note = updated;
        const index = this.pipedriveNotes.findIndex((n) => n.id == note.id);
        if (index !== -1) {
          this.pipedriveNotes.splice(index, 1, note);
        }
      } else if (kind == "activity") {
        const activity = updated;
        const index = this.pipedriveActivities.findIndex((a) => a.id == activity.id);
        if (index !== -1) {
          this.pipedriveActivities.splice(index, 1, activity);
        }
      } else {
        throw "Unknown update type!";
      }
    },
    _avatarUrl(user) {
      return user?.icon_url || "/img/avatar_120x120.png";
    },
    _fetchActivities() {
      activities
        .forOrganization(this.id, { limit: 1000 })
        .then((a) => (this.pipedriveActivities = a))
        .catch(() => this.$toasted.error("Could not fetch organization activity notes"));
    },
    _fetchNotes() {
      notes
        .forOrganization(this.id)
        .then((n) => (this.pipedriveNotes = n))
        .catch(() => this.$$toasted.error("Could not fetch organization notes"));
    },
    _initNoteContent() {
      // display unfinished note if we have one
      this.noteContent = retrieveNoteCache(this.noteCacheKey) || "";
    },
    _resetNote() {
      this.noteContent = "";
      this.addingNote = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.new-note
  margin: 10px 110px 10px 0
  .buttons
    margin: 4px 0

#pipedrive-notes :deep(.media + .media)
  margin-top: 0.75rem
</style>
