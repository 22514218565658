// shared state for google APIs & libraries

const state = {
  // google libraries (GIS & GAPI) are fully loaded
  librariesLoaded: false,
  // manual authorization needs to be run for the current user
  needsAuthorization: false,
  // libraries are fully loaded and token check has completed
  ready: false,
  // short lived access token (see README in common/google)
  token: null,
};

const mutations = {
  googleAuthorizationNeeded(state, needed) {
    state.needsAuthorization = needed;
  },
  googleLibraryLoadingComplete(state) {
    state.librariesLoaded = true;
  },
  resetGoogle(state) {
    state.needsAuthorization = true;
    state.token = null;
  },
  setGoogleReady(state, ready) {
    state.ready = ready;
  },
  setGoogleToken(state, token) {
    state.token = token;
  },
};

const actions = {};

const getters = {
  googleAuthorizationNeeded(state) {
    return state.needsAuthorization;
  },
  // google is fully loaded and we have a live token
  googleAvailable(state) {
    return state.ready && !!state.token?.token;
  },
  googleLibrariesLoaded(state) {
    return state.librariesLoaded;
  },
  googleReady(state) {
    return state.ready;
  },
  googleToken(state) {
    return state.token;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
