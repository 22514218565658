import { watch } from "vue";
import { store } from "@/store";

// ensure GIS is loaded an return reference
export const ensureGISLoaded = function () {
  return new Promise((resolve) => {
    if (store.getters.googleLibrariesLoaded) resolve(window.google.accounts);
    watch(
      () => store.getters.googleLibrariesLoaded,
      (loaded) => loaded && resolve(window.google.accounts)
    );
  });
};

// ensure initial google loading is complete, including requesting
// current access token and processing the result
export const ensureGoogleReady = function () {
  return new Promise((resolve) => {
    if (store.getters.googleReady) resolve();
    watch(
      () => store.getters.googleReady,
      (ready) => {
        if (ready) resolve();
      }
    );
  });
};

// ensure user is loaded and email is available
export const getUserEmail = function () {
  return new Promise((resolve) => {
    if (store.getters.userEmail) resolve(store.getters.userEmail);
    watch(
      () => store.getters.userEmail,
      (email) => {
        if (email) resolve(email);
      }
    );
  });
};
