<template>
  <header>
    <NavBar />
    <div v-if="authorizationNeeded" id="google-auth" class="has-background-warning">
      Google authorization is needed for Auger. Please
      <a @click="startGoogleAuthorization">authorize now</a>.
    </div>
  </header>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { startGoogleAuthorization } from "@/common/google/authorization";

export default {
  components: {
    NavBar,
  },
  computed: {
    authorizationNeeded() {
      return this.$store.getters.googleAuthorizationNeeded;
    },
  },
  methods: {
    startGoogleAuthorization,
  },
};
</script>

<style lang="sass">
#app > header
  background-color: $dark

#google-auth
  text-align: center
  padding: $space-xs
</style>
