// shared state for single member views / components

// TODO: move member fetching / refresh operations here instead of MemberShow?

import { monthEndDates, monthEndsFrom } from "@/utils/period";

const state = {
  member: {},
  refresh: false,
};

const mutations = {
  setMember(state, value) {
    state.member = value;
  },
  setMemberRefresh(state, value) {
    state.refresh = value;
  },
};

const actions = {
  updateMember({ commit }, member) {
    commit("setMember", member);
  },
  triggerMemberRefresh({ commit }) {
    commit("setMemberRefresh", true);
  },
  completeMemberRefresh({ commit }) {
    commit("setMemberRefresh", false);
  },
};

const getters = {
  currentMember(state) {
    return state.member;
  },
  currentMemberId(state) {
    return state.member.id;
  },
  memberPeriods(state) {
    if (state.member.founded_on) {
      return monthEndsFrom(state.member.founded_on);
    } else {
      return monthEndDates(9);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
