<template>
  <div id="big-chart" class="column is-three-fifths">
    <div class="chart">
      <line-chart
        :data="chartData"
        :label="$filters.capitalize(selectedMetric)"
        :prefix="prefix"
        :colors="['#3273dc']"
        :library="chartOpts"
        thousands=","
        height="320px"
      />
    </div>
    <div class="controls">
      <div class="metric-picker select">
        <select v-model="selectedMetric">
          <template v-for="(type, typeIndex) in ['operational', 'financial']" :key="typeIndex">
            <option disabled value="">{{ $filters.capitalize(type) }}:</option>
            <option
              v-for="(metric, index) in metricsByType[type]"
              :key="type + index"
              :value="metric.name"
            >
              {{ $filters.capitalize(metric.full_name) }}
            </option>
          </template>
        </select>
      </div>
      <div class="time-window-picker select is-pulled-right">
        <select v-model="timeWindow">
          <option v-for="w in fixedTimeWindows" :key="w.value" :value="w.value">
            {{ w.label }}
          </option>
          <option v-if="memberTimeWindows" disabled>──────────</option>
          <option v-for="w in memberTimeWindows" :key="w.value" :value="w.value">
            {{ w.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { extractYears, isSameDay } from "@/utils/date";
import { monthEndDates, yearlyRange } from "@/utils/period";
import { segmentAndOrder } from "@/common/metricSet";

export default {
  props: {
    metrics: {
      type: Array,
      required: true,
    },
    measurements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedMetric: "MRR",
      timeWindow: "1yr",
      chartOpts: {
        layout: {
          padding: {
            right: 10,
          },
        },
        tooltips: {
          intersect: false,
          callbacks: {
            title(tooltipItem, _data) {
              let dateString = tooltipItem[0].label;
              // eslint-disable-next-line no-unused-vars
              let [month, _day, year] = dateString.split(" ");
              return `${month} ${year}`;
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      if (this.measurements === []) return []; // account for load state
      let matching = this.measurements.filter((m) => m.metric === this.selectedMetric);
      let data = [];
      this.periods().forEach((period) => {
        let measure = matching.find((m) => isSameDay(m.ends_on, period));
        data.push([period, measure ? measure.value : null]);
      });
      return data;
    },
    isFinancial() {
      const metricDetails = this.metrics.find((m) => m.name === this.selectedMetric);
      if (!metricDetails) return false;
      return metricDetails.financial;
    },
    memberPeriods() {
      return this.$store.getters.memberPeriods;
    },
    memberPeriodYears() {
      return extractYears(this.memberPeriods).reverse();
    },
    metricsByType() {
      if (!this.metrics) return {};
      return segmentAndOrder(this.metrics);
    },
    prefix() {
      return this.isFinancial ? "$" : "";
    },
    fixedTimeWindows() {
      return [
        { label: "All dates", value: "all" },
        { label: "Last 5 years", value: "5yr" },
        { label: "Last 2 years", value: "2yr" },
        { label: "Last year", value: "1yr" },
      ];
    },
    memberTimeWindows() {
      return this.memberPeriodYears.map((year) => ({ label: year, value: year }));
    },
  },
  methods: {
    periods() {
      let month = new Date().getMonth();
      switch (this.timeWindow) {
        case "all":
          return this.memberPeriods;
        case "5yr":
          return monthEndDates(5 * 12 + month);
        case "2yr":
          return monthEndDates(2 * 12 + month);
        case "1yr":
          return monthEndDates(12 + month);
        default:
          return yearlyRange(this.timeWindow);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
#big-chart
  display: flex
  flex-direction: column
.chart
  flex: 1
</style>
