<template>
  <div id="app">
    <TheHeader v-if="showHeaderFooter" />
    <main>
      <router-view />
    </main>
    <TheFooter v-if="showHeaderFooter" />
  </div>
</template>

<script>
import userState from "@/services/userStateService";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import { initGoogle } from "@/common/google/init";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
  computed: {
    showHeaderFooter() {
      // don't show header and footer on login / auth pages
      return this.$store.getters.hadAuthenticatedSession;
    },
  },
  created() {
    userState.identify();
  },
  mounted() {
    initGoogle();
  },
};
</script>

<style lang="sass">
@import "./assets/styles/application"
@import "./common/toasted/sass/toast"
main
  min-height: 55rem
  padding-top: 0.5rem
</style>
