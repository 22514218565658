import { store } from "@/store";
import { apolloClient } from "@/init/apollo";
import { general as config } from "@/common/config";
import auth from "@/services/authService";
import Sentry from "@/init/sentry";
import queries from "@/common/queries";
import pick from "lodash/pick";

const UserStateService = (function () {
  auth.addListener("authenticate", updateAuthentication);

  // unmounted() {
  //   auth.removeListener("authenticate", this.onAuthenticate);
  // },

  function fetchUserProperties() {
    apolloClient
      .query({
        query: queries.fetchUser,
        variables: { id: store.getters.userId },
      })
      .then((queryResult) => {
        const fields = ["email", "name", "pipedrive_token", "partner"];
        if (queryResult.data?.user) {
          const userData = pick(queryResult.data.user, fields);
          store.dispatch("updateUserProperties", userData);
          setSentryUserContext(userData);
        }
      })
      .catch((error) => console.error("user property fetching error", error));
  }

  function replicateStateForUser(userId) {
    store.dispatch("setForcedUser", userId);
    fetchUserProperties();
  }

  function setSentryUserContext(userData) {
    const context = {
      id: store.state.user.userId,
      email: userData.email,
      username: userData.name,
    };
    Sentry.setUser(context);
  }

  async function updateAuthentication(state) {
    await store.dispatch("updateAuthInfo", state);
    if (state.loggedIn) {
      await fetchUserProperties();
    }
  }

  // expose public interface
  return {
    identify() {
      if (auth.enabled) {
        auth.renewTokens().catch((err) => console.log(err));
      } else if (config.forcedUser) {
        replicateStateForUser(config.forcedUser);
      }
    },

    // returns a promise which won't resolve until user is logged in & profile is loaded
    waitForLogin() {
      return new Promise((resolve) => {
        if (store.getters.userEmail) return resolve();
        // resolve when the store tells us we are ready
        store.watch(
          (_state, getters) => getters.userEmail,
          (newVal) => {
            if (newVal) resolve();
          }
        );
      });
    },
  };
})();

export default UserStateService;
