<template>
  <div v-show="member">
    <section class="member-detail-top level section">
      <div class="level-left">
        <h1 class="is-size-1 level-item">{{ member.name }}</h1>
        <div class="level-item">
          <PipedriveButton :href="pipedriveUrl" />
        </div>
        <div class="level-item">
          <CartaButton :href="cartaUrl" :class="{ 'is-disabled': !member.carta_id }" />
        </div>
      </div>

      <div class="details level-right">
        <div class="level-item">
          <MemberTags :member="member" />
        </div>
        <div class="level-item">
          <a class="button" title="Edit member attributes" @click="editing = !editing">
            <span class="icon"><font-awesome-icon icon="cog" /></span>
            <span>Edit</span>
          </a>
        </div>
      </div>
    </section>

    <section class="member-detail-viz section">
      <div class="columns">
        <MainFunnel :measurements="measurements" :sequence="funnelSequence" />
        <MemberChart :measurements="measurements" :metrics="metrics" />
        <div id="blocknums" class="column is-narrow">
          <BigStat title="Runway" :num="runway" units="months" />
          <BigStat
            title="ARR"
            :num="numsOnly(arr)"
            :units="expandUnits(arr)"
            :trailing-average="trailingArrGrowth"
          />
        </div>
      </div>
    </section>
    <section class="member-detail-tabs section">
      <MemberTabs
        :id="id"
        :pipedrive-id="member.pipedrive_id"
        :metrics="metrics"
        :measurements="measurements"
        :initial-tab="tab"
        @select-tab="updateTab"
      />
    </section>
    <MemberEdit
      :id="id"
      :active="editing"
      :edit-props="editProps"
      :default-funnel="defaultFunnelSequence"
      @close-edit="editing = false"
    />
  </div>
</template>

<script>
import BigStat from "@/components/BigStat.vue";
import MainFunnel from "@/components/MainFunnel.vue";
import MemberChart from "@/components/MemberChart.vue";
import MemberEdit from "@/components/MemberEdit.vue";
import MemberTabs from "@/components/MemberTabs.vue";
import MemberTags from "@/components/MemberTags.vue";
import PipedriveButton from "@/components/PipedriveButton.vue";
import CartaButton from "@/components/CartaButton.vue";

import { lastARR, t4Growth, currentRunway } from "@/utils/analysis";
import { currencyShort } from "@/utils/numberFormatter";
import { forGTM as funnelForGTM } from "@/common/funnels";
import queries from "@/common/queries";
import pipedrive from "@/common/pipedrive";
import carta from "@/common/carta";

export default {
  components: {
    BigStat,
    MainFunnel,
    MemberChart,
    MemberEdit,
    MemberTabs,
    MemberTags,
    PipedriveButton,
    CartaButton,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    tab: {
      type: String,
      default: "notes",
    },
  },
  data: function () {
    return {
      member: {},
      editing: false,
      measurements: [],
    };
  },
  apollo: {
    member: {
      query: queries.fetchMember,
      variables() {
        return {
          id: this.id,
        };
      },
      error() {
        this.$toasted.error("Error loading member data.");
      },
    },
    measurements: {
      query: queries.memberMeasurements,
      variables() {
        return {
          id: Number(this.id),
        };
      },
      error() {
        this.$toasted.error("Error loading member measurements.");
      },
    },
  },
  computed: {
    // initial state for the edit component
    editProps() {
      let member = this.member;
      if (Object.keys(member).length === 0) return {};
      return {
        name: member.name,
        founded_on: member.founded_on,
        entered_heavybit_on: member.entered_heavybit_on,
        fundraising: member.fundraising,
        funnel: member.funnel,
        go_to_market_id: member.go_to_market ? member.go_to_market.id : null,
        member_stage_id: member.member_stage.id,
        company_stage_id: member.company_stage.id,
        exit_type_id: member.exit_type ? member.exit_type.id : null,
        pipedrive_id: member.pipedrive_id,
        carta_id: member.carta_id,
      };
    },
    funnelSequence() {
      if (this.member && this.member.funnel) {
        return this.member.funnel;
      } else {
        return this.defaultFunnelSequence;
      }
    },
    defaultFunnelSequence() {
      if (!this.member || !this.member.go_to_market) return [];
      const gtmName = this.member.go_to_market.name;
      return funnelForGTM(gtmName);
    },
    metrics() {
      if (!this.member.members_metrics) return [];
      return this.member.members_metrics.map((mm) => mm.metric);
    },
    arr() {
      const arr = lastARR(this.measurements);
      return arr ? currencyShort(arr) : "-";
    },
    trailingArrGrowth() {
      return t4Growth(this.measurements, "MRR");
    },
    runway() {
      const run = currentRunway(this.measurements);
      return run ? run.toFixed(1) : "-";
    },
    pipedriveUrl() {
      if (!this.member) return "";
      return pipedrive.web.organizationUrl(this.member.pipedrive_id);
    },
    cartaUrl() {
      return carta.web.organizationUrl(this.member.carta_id);
    },
    refreshNeeded() {
      return this.$store.state.member.refresh;
    },
  },
  watch: {
    member(newVal) {
      this.$store.dispatch("updateMember", this.simplifyMember(newVal));
    },
    refreshNeeded(newVal) {
      if (newVal) {
        this.refreshMember();
        this.$store.dispatch("completeMemberRefresh");
      }
    },
  },
  methods: {
    expandUnits(bignum) {
      if (!bignum) return "";
      let last = bignum.slice(-1);
      if (last === "M") {
        return "million";
      } else if (last === "k") {
        return "thousand";
      } else {
        return "";
      }
    },
    numsOnly(bignum) {
      if (!bignum) return "-";
      if (["M", "k"].includes(bignum.slice(-1))) {
        return bignum.slice(0, -1);
      } else {
        return bignum;
      }
    },
    refreshMember() {
      this.$apollo.queries.member.refetch();
    },
    simplifyMember(member) {
      // omit bigger properties we may not want to pass all over the place
      const { members_metrics, ...rest } = member;
      return rest;
    },
    updateTab(tab) {
      if (this.$route.query == tab) return; // Don't navigate if we're already there
      this.$router.replace({ query: { tab: tab } });
    },
  },
};
</script>

<style lang="sass" scoped>
h1
  display: inline-block
.member-detail-top
  margin-bottom: 0
  padding-bottom: 1rem
.member-detail-viz
  padding-top: 0
.member-detail-tabs
  padding-top: 0
  min-height: 400px
  margin-bottom: 30px
</style>
